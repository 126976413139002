import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from 'state/types';
import type { Account as AccountType } from '../../state/global';
import { buildAddress, createOptions, currencyFormatter, getApiUrl } from "../../utils";

const getQueryParam = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('accountId')
}

const createTableCell = (value) => {
  return <td className="group-hover:text-blue-600 pr-6 py-4 text-blue-600">
    <div>{value}</div>
  </td>
}

const TOTAL_PROJECTS_HEADER_TEXT = 'Total: '
export const tableHeaders = [
  TOTAL_PROJECTS_HEADER_TEXT,
  'Project #',
  'Site',
  'Site Address',
  'Project Type',
  'Status',
  'Fees',
  // 'Invoiced Fees',
  // '% Invoiced'
]

export const Account = () => {
  const navigate = useNavigate()
  const accountId = getQueryParam()
  const [accountData, setAccountData] = useState<{ account: AccountType | null, projects: Project[] | null }>({
    account: null,
    projects: [],
  })

  useEffect(() => {
    const getAccountData = async () => {
      const url = getApiUrl(`accounts/${accountId}`)

      const data = await fetch(url, createOptions())
      const jsoned = await data.json()

      setAccountData(jsoned)
      // updateGlobalState(jsoned)
    }

    getAccountData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickProject = (projectNumber: number) => {
    navigate(`/project/${projectNumber}`)
  }

  if (accountData.account === null) {
    return null
  }

  // const builtAddressInfo = buildAddress({ unit: accountData.account.unit, street_address: accountData.account.street_address, city: accountData.account.city, province: accountData.account.province, country: accountData.account.country, postal_code: accountData.account.postal_code, })

  return (
    <>
      {
        !!accountId && accountData ?
          <section className="container px-4 mx-auto w-full max-w-screen-xl ">
            <div className="flex items-center justify-center w-min md:w-full px-4 md:px-4">
              <div className="flex flex-col justify-center items-center w-full">
                <div className="max-w-lg">
                  <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                    Account: {accountData.account.email}
                  </h3>
                  <p className="flex text-gray-600 mt-2 text-lg">
                    <span className="font-bold">Point of contact:</span>
                    <a href={`mailto:${accountData.account.poc}`}>
                      <span className="hover:text-blue-600">&nbsp;{accountData.account.poc}</span>
                    </a>
                  </p>
                  <p className="flex text-gray-600 mt-2 text-lg">
                    <span className="font-bold">Email:</span>
                    <a href={`mailto:${accountData.account.email}`}>
                      <span className="hover:text-blue-600">&nbsp;{accountData.account.email}</span>
                    </a>
                  </p>
                  <p className="flex text-gray-600 mt-2 text-lg">
                    <span className="font-bold">Phone #</span>
                    <a href={`tel:${accountData.account.phone}`}>
                      <span className="hover:text-blue-600">&nbsp;{accountData.account.phone}</span>
                    </a>
                  </p>

                </div>
                <div className="my-8">
                  <h3 className="text-gray-800 text-2xl font-bold">
                    List of projects for {accountData.account.name}:
                  </h3>
                </div>
                <div className="text-sm w-full">
                  <table className="w-full table-auto text-left">
                    <thead className="text-gray-600 font-medium border-b">
                      <tr>
                        {
                          tableHeaders.map((header, index) => {
                            return (
                              <th className={`py-4 ${index === 0 ? '' : 'pr-6'}`}>{header === TOTAL_PROJECTS_HEADER_TEXT ? `${TOTAL_PROJECTS_HEADER_TEXT} ${accountData.projects.length}` : header}</th>
                            )
                          })
                        }
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                      {
                        Object.values(accountData.projects).map(({
                          id,
                          project_number,
                          unit,
                          street_address,
                          city,
                          province,
                          postal_code,
                          country,
                          site,
                          project_type,
                          fee,
                          // invoiced_fee,
                          status
                        }, orderIndex) => {
                          const projectBuiltAddress = buildAddress({
                            unit,
                            street_address,
                            city,
                            province,
                            postal_code,
                            country
                          })

                          return (
                            // IDIDID
                            <tr className="cursor-pointer group" onClick={() => { handleClickProject(project_number) }}>
                              {createTableCell(orderIndex + 1)}
                              {createTableCell(project_number)}
                              {createTableCell(site)}
                              {/* TODO: New line here */}
                              {createTableCell(projectBuiltAddress.builtAddress)}
                              {createTableCell(project_type)}
                              {createTableCell(status)}
                              {createTableCell(currencyFormatter().format(fee))}
                              {/* {createTableCell(invoiced_fee)} */}
                              {/* {createTableCell(`${calcPercentInvoiced(invoiced_fee, fee)}%`)} */}
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section > : <div>No account ID</div>
      }
    </>
  )
}

