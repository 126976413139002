import React, { useState } from 'react'
import { Project } from "state/types"

const tabColors = {
  ALL: 'border-blue-600 text-blue-600 hover:text-blue-600',
  ACTIVE: 'border-emerald-500 text-emerald-500 hover:text-emerald-500',
  'PENDING PROPOSAL': 'border-red-700 text-red-700 hover:text-red-700',
  'PROPOSAL SENT': 'border-yellow-200 text-yellow-500 hover:text-yellow-500',
  COMPLETE: 'border-gray-200 text-black-500 hover:text-black-500',
  LOST: 'border-amber-400 text-amber-800 hover:text-black-100',
}

const chipColors = {
  ALL: 'text-blue-600 bg-blue-200',
  ACTIVE: 'text-emerald-500 bg-emerald-200',
  'PENDING PROPOSAL': 'text-red-700 bg-red-100',
  'PROPOSAL SENT': 'text-yellow-500 bg-yellow-200',
  COMPLETE: 'text-black-500 bg-gray-200',
  LOST: 'text-amber-800 bg-amber-400',
}

type ProjectStatus = 'ALL' | 'ACTIVE' | 'PENDING PROPOSAL' | 'PROPOSAL SENT' | 'COMPLETE' | 'LOST'

type Props = {
  statuses: Project['status'][];
  isEditingProjectRow: boolean;
  projects: Record<ProjectStatus, Project[]>;
  handleStatusClick: (status: Project['status']) => void;
}


export const StatusBar = ({ statuses, isEditingProjectRow, projects, handleStatusClick }: Props) => {
  const [selectedProjectStatus, setSelectedProjectStatus] = useState('ACTIVE')

  const warnDisabledClick = () => {
    alert("You can't perform this action while editing a project row")
  }

  return (
    <ul role="tablist" className="w-full border-b flex items-center gap-x-3">
      {
        statuses.map((status, idx) => {
          return (
            <li key={status} className={`flex py-2 border-b-2 ${selectedProjectStatus === status ? tabColors[status] : "border-white text-gray-500"}`}>
              <button
                role="tab"
                aria-selected={selectedProjectStatus === status ? true : false}
                aria-controls={`tabpanel-${idx + 1}`}
                className={`${isEditingProjectRow ? 'cursor-not-allowed' : ''} py-2.5 px-4 rounded-lg duration-150 hover:bg-gray-50 active:bg-gray-100 font-medium ${selectedProjectStatus === status ? tabColors[status] : null}`}
                onClick={isEditingProjectRow ? warnDisabledClick : () => {
                  setSelectedProjectStatus(status)
                  handleStatusClick(status)
                }}
              >
                {status.toUpperCase()}
              </button>
              <div className="py-4 text-sm font-medium whitespace-nowrap">
                {/* TODO: DARKMODE: dark:bg-gray-800 */}
                <div className={`inline px-3 py-1 text-sm font-normal rounded-full gap-x-2 ${chipColors[status]}`}>
                  {projects[status].length}
                </div>
              </div>
            </li>
          )
        })
      }
    </ul>
  )
}