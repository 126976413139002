import React from 'react'
import { getAllProjectTypes } from "../utils";
import { Project } from 'state/types';
import { Close } from '../../../components/icons';

type ListOfProjectTypesProps = {
  addedProjectTypes: string[];
  projects: Project[];
  handleClick: (projectType: string) => void;
  closeList: () => void;
}

export const ListOfProjectTypes = ({ addedProjectTypes, projects, handleClick, closeList }: ListOfProjectTypesProps) => {
  return (
    <div className="absolute top-9 left-[-60px] bg-slate-100 w-[240px] rounded-lg">
      <div>
        <div className="flex flex-col p-4">
          <div className="flex justify-end">
            <Close closeModal={closeList} />
          </div>
          <h2 className="text-md font-bold mb-4">Project Types</h2>
          <ul className="flex flex-col gap-4">
            {getAllProjectTypes(projects).map((projectType) => (
              <li key={projectType} className="flex gap-2">
                <input
                  checked={addedProjectTypes.includes(projectType)}
                  type="checkbox"
                  value={projectType}
                  id={projectType}
                  name={projectType}
                  onClick={() => {
                    handleClick(projectType)
                  }}
                  // Placing this for HTML React warning
                  onChange={() => {}}
                  />
                <label htmlFor={projectType} className="text-sm capitalize">{projectType}</label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}