import { useState } from "react"

export const useToggleListOfProjectTypes = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [addedProjectTypes, setAddedProjectTypes] = useState<string[]>([]);

  return {
    addedProjectTypes,
    updateAddedProjectTypes: (projectType: string) => {
      setAddedProjectTypes((prev) => {
        if (prev.includes(projectType.toLowerCase())) {
          return prev.filter((type) => type !== projectType);
        }
        return [...prev, projectType.toLowerCase()];
      })
    },
    isListOfProjectTypesOpen: isOpen,
    toggleListOfProjectTypes: () => setIsOpen(!isOpen),
  }
}