export const PROJECT_COLUMNS = [
  'Project #',
  'Account',
  'Site',
  'Site Address',
  'Project Type',
  'Status',
  'Total Fees',
  'Awarded',
  'Comments',
] as const;

export type ProjectColumn = typeof PROJECT_COLUMNS[number];