import React, { useState } from 'react';
import { Project } from 'state/types';
import { DropdownSearch } from '../../shared';
import { ProjectNumberPayload } from '../../state/services';
import { buildAddress, currencyFormatter } from '../../utils';
import { CommentsModal } from './comments-modal';
import { Address, EditAddressModal } from './edit-address-modal';
import { InvoiceAndPaymentsModal } from './invoice/invoice-and-payments-modal';
import { getAllSites } from './utils';

type EditProjectRowType = {
  allProjectsData: Project[]
  idx: number
  projectToEdit: Project
  saveAddress: (addressInfo: Address) => void
  saveEdit: (key: string, value: string, saveToBackend?: boolean) => void
  handleSaveClick: (isSaving: boolean) => void
  updateProjectNumber: (projectNumberPayload: ProjectNumberPayload) => void
}
// TODO: Update so that it doesn't NEED to set local state before updating project to DB, e.g. the saveToBackend param
export const EditProjectRow = ({ allProjectsData, idx, projectToEdit, updateProjectNumber, saveAddress, saveEdit, handleSaveClick }: EditProjectRowType) => {
  const [hasBeenEdited, setHasBeenEdited] = useState(false)
  const [isEditingProjectNumber, setIsEditingProjectNumber] = useState(false)
  const [projectNumberToUpdate, setProjectNumberToUpdate] = useState(projectToEdit.project_number)
  const [isSiteOpen, setIsSiteOpen] = useState(false)
  const [isAddressOpen, setIsAddressOpen] = useState(false)
  const [isComentsOpen, setIsCommentsOpen] = useState(false)
  const [isProjectStatusOpen, setIsProjectStatusOpen] = useState(false)
  const [isInvoiceAndPaymentsOpen, setIsInvoiceAndPaymentsOpen] = useState(false)
  const [isProjectTypeOpen, setIsProjectTypeOpen] = useState(false)
  const { builtAddress } = buildAddress({ unit: projectToEdit.unit, street_address: projectToEdit.street_address, city: projectToEdit.city, province: projectToEdit.province, country: projectToEdit.country, postal_code: projectToEdit.postal_code, })

  const handleTotalFeesClick = () => {
    setIsInvoiceAndPaymentsOpen(true)
  }

  const tempSave = (key: string, value: string) => {
    setHasBeenEdited(true)
    saveEdit(key, value)
  }

  return (
    <>
      {isInvoiceAndPaymentsOpen ? <InvoiceAndPaymentsModal
        projects={allProjectsData}
        projectId={projectToEdit.id}
        closeModal={() => { setIsInvoiceAndPaymentsOpen(false) }}
      /> : null
      }
      {
        isAddressOpen
          ? <EditAddressModal
            saveAddress={saveAddress}
            project={projectToEdit}
            closeModal={() => { setIsAddressOpen(false); }}
          />
          : null
      }
      <tr key={idx} className="bg-blue-100 cursor-pointer group">
        <td className="group-hover:text-blue-600 px-2 py-4 text-blue-600">
          <div className="w-full">
            {
              isEditingProjectNumber
                ? (
                  <label className="text-sm text-gray-700" htmlFor="edit-project-project_number">
                    <input
                      type="text"
                      name="edit-project-project_number"
                      id="edit-project-project_number"
                      value={projectNumberToUpdate}
                      onChange={(e) => {
                        if (Number(e.target.value) !== projectNumberToUpdate) {
                          setProjectNumberToUpdate(Number(e.target.value))
                          return
                        }
                      }}
                      onBlur={(e) => {
                        if (projectNumberToUpdate !== projectToEdit.project_number) {
                          setHasBeenEdited(true)
                        }
                      }}
                      className="w-full block px-2 py-1 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />

                  </label>
                ) : (
                  <button
                    className="cursor-pointer py-1 px-2 font-medium text-blue-600 hover:text-sky-700 duration-150 hover:bg-gray-50 rounded-lg"
                    type="button"
                    onClick={() => {
                      setIsEditingProjectNumber(true)
                    }}
                  >
                    {projectToEdit.project_number}
                  </button>
                )
            }
          </div>
        </td>

        {
          isEditingProjectNumber
            ? (
              <>
                <td className="cursor-not-allowed">{projectToEdit.account_name}</td>
                <td className="cursor-not-allowed">{projectToEdit.site}</td>
                <td className="cursor-not-allowed">{builtAddress}</td>
                <td className="cursor-not-allowed">{projectToEdit.project_type}</td>
                <td className="cursor-not-allowed">{projectToEdit.status}</td>
                <td className="cursor-not-allowed">{projectToEdit.fee}</td>
                <td className="cursor-not-allowed">{projectToEdit.awarded}</td>
                <td className="cursor-not-allowed">{projectToEdit.comments}</td>
              </>
            )
            : (
              <>
                <td className="group-hover:text-blue-600 px-2 py-4 text-blue-600">
                  <div className="w-full">{projectToEdit.account_name}</div>
                </td >
                <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600">
                  <div className="relative w-full h-[40px]">
                    <div className="absolute top-0 left-0 bg-white-100 z-1">
                      <DropdownSearch
                        isOpen={isSiteOpen}
                        toggleOpen={() => { setIsSiteOpen(true) }}
                        addNew
                        initValue={projectToEdit.site}
                        closeDropdown={() => {
                          setIsSiteOpen(false)
                        }}
                        saveCallback={(value) => {
                          tempSave('site', value)
                        }}
                        list={getAllSites(allProjectsData)}
                      />
                    </div>
                  </div>
                </td>
                <td
                  className="px-2 py-4 group-hover:text-blue-600 text-blue-600">
                  <button
                    className="flex text-left"
                    type="button"
                    onClick={() => {
                      setIsAddressOpen(true)
                    }}
                  >
                    {builtAddress === '' ? 'Click to add address' : builtAddress}
                  </button>
                </td>
                <td
                  className="px-2 py-4 group-hover:text-blue-600 text-blue-600 relative">
                  <div className="relative w-full h-[40px]">
                    <div className="absolute top-0 left-0 bg-white-100 z-1">
                      <DropdownSearch
                        isOpen={isProjectTypeOpen}
                        toggleOpen={() => { setIsProjectTypeOpen(true) }}
                        addNew
                        initValue={projectToEdit.project_type}
                        closeDropdown={() => {
                          setIsProjectTypeOpen(false)
                        }}
                        saveCallback={(value) => {
                          tempSave('project_type', value)
                        }}
                        list={allProjectsData.reduce((accum, projectData) => {
                          if (!accum.some((element) => element.toLowerCase() === projectData.project_type.toLowerCase())) {
                            accum.push(projectData.project_type)
                          }
                          return accum
                        }, [])}
                      />
                    </div>
                  </div>
                </td>
                <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600 relative">
                  <div className="relative w-full h-[40px]">
                    <div className="absolute top-0 left-0 bg-white-100 z-1">
                      <DropdownSearch
                        isOpen={isProjectStatusOpen}
                        toggleOpen={() => { setIsProjectStatusOpen(true) }}
                        initValue={projectToEdit.status}
                        closeDropdown={() => {
                          setIsProjectStatusOpen(false)
                        }}
                        saveCallback={(value) => {
                          tempSave('status', value)
                        }}
                        list={['ACTIVE', 'PENDING PROPOSAL', 'PROPOSAL SENT', 'COMPLETE', 'LOST']}
                      />
                    </div>
                  </div>
                </td>
                <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600">
                  <button
                    type="button"
                    className="w-auto"
                    onClick={handleTotalFeesClick}
                  >{currencyFormatter().format(projectToEdit.fee)}</button>
                </td>
                <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600">
                  <button
                    type="button"
                    className="w-auto"
                    onClick={handleTotalFeesClick}
                  >{currencyFormatter().format(projectToEdit.awarded)}</button>
                </td>
                {
                  isComentsOpen ? <CommentsModal comments={projectToEdit.comments} saveComments={(comments) => {
                    tempSave('comments', comments)
                  }} closeModal={() => {
                    setIsCommentsOpen(false)
                  }} /> : <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600" onClick={() => { setIsCommentsOpen(true) }}>
                    {/* TODO: replace dangerouslySetInnerHTML with safer inpu */}
                    <div>{(projectToEdit.comments !== '<p></p>') ? <div dangerouslySetInnerHTML={{ __html: projectToEdit.comments }} /> : 'Click to add comments'}</div>
                  </td>
                }
              </>
            )
        }

        <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600">
          <button
            // TODO: Better UI logic here to allow save
            className={hasBeenEdited ? 'relative inline-block px-4 py-2 text-white duration-150 font-medium bg-blue-600 rounded-lg hover:bg-sky-700 active:bg-sky-500 md:text-sm' : 'inline-block px-4 py-2 text-white duration-150 font-medium bg-gray-400 rounded-lg cursor-not-allowed md:text-sm'}
            onClick={isEditingProjectNumber ? () => {
              if (projectNumberToUpdate !== projectToEdit.project_number) {
                const prompt = window.prompt('This is sensitive data. Are you sure you want to edit project number?\nType Y to confirm. Type any other key to cancel.\nIf you had any unsaved changes for this project row, these will be reset')
                if (prompt?.toLowerCase() !== 'y') {
                  alert('Project Number has NOT been saved')

                } else {
                  updateProjectNumber({
                    project_number_old: projectToEdit.project_number,
                    project_number_new: projectNumberToUpdate
                  })
                  setIsEditingProjectNumber(false)
                  setHasBeenEdited(false)
                }
              }
            } : hasBeenEdited ? async () => {
              handleSaveClick(true)
            } : null}>
            Save
            {
              hasBeenEdited
                ? (
                  <div className="absolute top-[-4px] right-[-4px]">
                    <span className="relative flex h-4 w-4">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-300 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-4 w-4 bg-sky-300"></span>
                    </span>
                  </div>
                )
                : null
            }
          </button>
        </td>
        <td className="px-2 py-4 group-hover:text-blue-600 text-blue-600">
          <button
            onClick={() => {
              handleSaveClick(false)
            }}>
            Cancel
          </button>
        </td>
      </tr >
    </>
  )
}