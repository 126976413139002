import type { Project } from "state/types"

export const tableHeaders = [
  'Project #',
  'Site',
  'Site Address',
  'Project Type',
  'Status',
]

export const getAllProjectTypes = (allProjectsData: Project[]): string[] => {
  return [...new Set(allProjectsData.map((project) => project.project_type.toLowerCase()))]
}

export const getAllSites = (allProjectsData: Project[]) => {
  return [...new Set(allProjectsData.map((project) => project.site))]
}

export const generateFiscalYears = () => Array.from(Array(4).keys(), x => x + 2024)

export const generateQuarters = () => Array.from(Array(4).keys(), x => x + 1)